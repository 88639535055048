class AButtonToggleNav {
	mediaQuery = '(min-width: calc(800 / 16 * 1em))';

	prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

	elementsKeyframes = {
		translate: ['0 -2rem', '0 0'],
		opacity: [0, 1],
	};

	constructor(element) {
		this.element = element;
		this.mainNavElement = document.querySelector('.m-main-nav');
		this.htmlElement = document.querySelector('html');
		this.elementsToInert = document.querySelectorAll('body > *:not(.o-header)');

		this.elementsToAnimate = this.mainNavElement.querySelectorAll('.m-main-nav__headline, .m-main-nav__link');

		window.addEventListener('resize', this.onResize.bind(this));
		this.element.addEventListener('click', this.toggle.bind(this));
		document.addEventListener('keydown', (event) => {
			if (event.key === 'Escape') {
				this.close();
			}
		});

		this.onResize();
	}

	get isOpen() {
		return !this.mainNavElement.hasAttribute('data-open');
	}

	toggle() {
		if (this.isOpen) {
			this.open();
		} else {
			this.close();
		}
	}

	open() {
		if (this.element.ariaExpanded === 'false') {
			this.element.ariaExpanded = 'true';
			this.mainNavElement.toggleAttribute('data-open', true);
			this.htmlElement.toggleAttribute('data-no-scroll', true);
			this.elementsToInert.forEach((elementToInert) => {
				elementToInert.toggleAttribute('inert', true);
			});
			this.htmlElement.scroll({
				top: 0,
			}, {
				behavior: this.prefersReducedMotion ? 'auto' : 'smooth',
			});

			if (!this.prefersReducedMotion) {
				this.mainNavElement.animate({
					opacity: [0, 1],
				}, {
					duration: 300,
				});
				this.elementsToAnimate.forEach((elementToAnimate, index) => {
					elementToAnimate.animate(this.elementsKeyframes, {
						duration: 300,
						delay: 20 * (index),
						fill: 'backwards',
						easing: 'ease-out',
					});
				});
			}
		}
	}

	close() {
		if (this.element.ariaExpanded === 'true') {
			this.element.ariaExpanded = 'false';
			this.htmlElement.toggleAttribute('data-no-scroll', false);
			this.elementsToInert.forEach((elementToInert) => {
				elementToInert.toggleAttribute('inert', false);
			});

			if (this.prefersReducedMotion) {
				this.mainNavElement.toggleAttribute('data-open', false);
			} else {
				const elementsAnimations = [];
				const animation = this.mainNavElement.animate({
					opacity: 0,
				}, {
					duration: 200,
					easing: 'linear',
				});
				this.elementsToAnimate.forEach((elementToAnimate, index) => {
					const elementAnimation = elementToAnimate.animate(this.elementsKeyframes, {
						duration: 200,
						delay: 20 * (index),
						fill: 'forwards',
						easing: 'linear',
						direction: 'reverse',
					});
					elementsAnimations.push(elementAnimation);
				});
				animation.addEventListener('finish', () => {
					this.mainNavElement.toggleAttribute('data-open', false);
					elementsAnimations.forEach((elementAnimation) => {
						elementAnimation.cancel();
					});
				});
			}
		}
	}

	onResize() {
		const computedStyle = window.getComputedStyle(this.element);
		if (computedStyle.display === 'none') {
			this.close();
			this.mainNavElement.removeAttribute('role');
		} else {
			this.mainNavElement.setAttribute('role', 'dialog');
		}
	}
}

const element = document.querySelector('.a-button-toggle-nav');
if (element) {
	// eslint-disable-next-line no-new
	new AButtonToggleNav(element);
}
