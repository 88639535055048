if (window.ViewTimeline) {
	if (!window.matchMedia('(prefers-reduced-motion: reduce) and (min-width: calc(1080 / 16 * 1em)').matches) {
		const elements = document.querySelectorAll('.o-product-teaser');
		elements.forEach((element) => {
			const teaserImageElement = element.querySelector('.o-product-teaser__teaser-image');
			const productImageElement = element.querySelector('.o-product-teaser__product-image');

			teaserImageElement.animate(
				{
					translate: ['0 10rem', '0 0'],
				},
				{
					fill: 'both',
					timeline: new ViewTimeline({
						subject: element,
					}),
					easing: 'ease-out',
					rangeStart: 'cover -50%',
					rangeEnd: 'entry 100%',
				},
			);

			productImageElement.animate(
				{
					translate: ['0 -40rem', '0 0'],
				},
				{
					fill: 'both',
					timeline: new ViewTimeline({
						subject: element,
					}),
					easing: 'ease-out',
					rangeStart: 'cover -50%',
					rangeEnd: 'entry 100%',
				},
			);
		});
	}
}
