class MGalleryColor extends HTMLElement {
	static get observedAttributes() {
		return ['current-image'];
	}

	imagesCount = null;

	storage = {};

	translations = {
		en: {
			navigationLabel: 'Gallery controls (Colors)',
		},
		de: {
			navigationLabel: 'Galerie-Steuerung (Farben)',
		},
	};

	constructor() {
		super();

		const language = this.closest('[lang]')?.lang;
		const translations = this.translations[language] ?? this.translations.en;

		const imagesListElement = this.querySelector(':scope > ul');
		const mSurfaceSwatchesElements = document.querySelectorAll('m-surface-swatches');

		this.imagesCount = imagesListElement.childElementCount;

		this.template = Object.assign(document.createElement('template'), {
			innerHTML: `
				<nav class="m-gallery-color__navigation" aria-label="${translations.navigationLabel}">
					<span class="m-gallery-color__color-label"></span>
					<ul>
						${[...imagesListElement.children].map((listElement, index) => `
							<li>
								<button
									class="a-color-swatch"
									data-index="${index}"
									data-color-text="${listElement.dataset.colorText}"
									data-action="show-image"
									style="
										--color: ${listElement.dataset.color};
										--image: url(${listElement.dataset.image});
									"
									aria-label="${listElement.dataset.name}"
								>

								</button>
							</li>
						`).join('')}
					</ul>
				</nav>
				<div class="m-gallery-color__images">
					${imagesListElement.outerHTML}
				</div>
			`,
		});

		this.colorLabelElement = this.template.content.querySelector('.m-gallery-color__color-label');
		this.imagesElement = this.template.content.querySelector('.m-gallery-color__images');
		this.imagesUlElement = this.imagesElement.querySelector(':scope > ul');
		this.imgElements = this.imagesUlElement.querySelectorAll(':scope > li > img');

		this.navElement = this.template.content.querySelector('.m-gallery-color__navigation');
		this.paginationButtonElements = this.navElement.querySelectorAll('[data-action="show-image"]');

		// Functions
		// Utility function to check if an element is partially in view
		const isElementPartiallyInViewport = (element) => {
			const rect = element.getBoundingClientRect();
			const windowHeight = window.innerHeight || document.documentElement.clientHeight;
			const windowWidth = window.innerWidth || document.documentElement.clientWidth;

			return (
				rect.top < windowHeight
				&& rect.bottom > 0
				&& rect.left < windowWidth
				&& rect.right > 0
			);
		};

		// Intersection Observer
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				entry.target.toggleAttribute('data-is-intersecting', entry.isIntersecting);
			});
		}, {
			threshold: 0.5, // Check if at least 50% of the element is visible
			rootMargin: '20px',
			root: this,
		});
		[...this.imgElements].forEach((imgElement) => {
			observer.observe(imgElement);
		});

		// Event Listener
		const onKeyDown = (event) => {
			if (isElementPartiallyInViewport(this.imagesUlElement)) {
				if (event.key === 'ArrowLeft') {
					event.preventDefault();
					this.showImage(this.currentImage - 1 < 0 ? this.imagesCount - 1 : this.currentImage - 1);
				} else if (event.key === 'ArrowRight') {
					event.preventDefault();
					this.showImage(this.currentImage + 1 < this.imagesCount ? this.currentImage + 1 : 0);
				}
			}
		};
		document.addEventListener('keydown', onKeyDown);
		this.paginationButtonElements.forEach((paginationButtonElement) => {
			paginationButtonElement.addEventListener('click', () => {
				this.currentImage = paginationButtonElement.dataset.index;
			});
		});
		mSurfaceSwatchesElements.forEach((mSurfaceSwatchesElement) => {
			mSurfaceSwatchesElement.addEventListener('surfaceUpdate', (event) => {
				const surfaceId = event.detail;
				const index = [...this.imagesUlElement.children]
					.findIndex((_) => _.dataset.surfaceId === surfaceId);
				this.showImage(index);
			});
		});
	}

	get currentImage() {
		return this.storage.currentImage;
	}

	set currentImage(value) {
		if (this.storage.currentImage !== value) {
			this.storage.currentImage = value;
			this.setAttribute('current-image', value);
		}
	}

	showImage(index) {
		[...this.imagesUlElement.children].forEach((liElement, _) => {
			liElement.toggleAttribute('inert', _ !== index);
		});
		this.currentImage = index;

		const liElement = [...this.imagesUlElement.children][index];
		if (liElement) {
			const event = new CustomEvent('surfaceUpdate', { detail: liElement.dataset.surfaceId });
			this.dispatchEvent(event);
		}
	}

	updateNav() {
		const {
			paginationButtonElements,
			currentImage,
		} = this;

		paginationButtonElements.forEach((paginationButtonElement) => {
			const index = parseInt(paginationButtonElement.dataset.index, 10);
			paginationButtonElement.toggleAttribute('aria-pressed', currentImage === index);
			paginationButtonElement.toggleAttribute('aria-current', currentImage === index);
		});

		const pressedPaginationButtonElement = [...paginationButtonElements].find((_) => _.hasAttribute('aria-pressed'));

		this.colorLabelElement.innerHTML = pressedPaginationButtonElement.getAttribute('aria-label');
		this.colorLabelElement.style.setProperty('--color', pressedPaginationButtonElement.dataset.colorText);
	}

	render() {
		this.innerHTML = '';
		this.appendChild(this.template.content);
		this.updateNav();
	}

	attributeChangedCallback(name, oldValue, newValue) {
		if (name === 'current-image') {
			const parsedValue = parseInt(newValue, 10);
			if (this.currentImage !== parsedValue) {
				this.showImage(parsedValue);
				this.currentImage = parsedValue;
			}
			this.updateNav();
		}
	}

	connectedCallback() {
		// set default attributes
		if (this.getAttribute('current-image') === null) {
			this.currentImage = 1;
		}

		this.showImage(0);

		// Initial rendering
		this.render();
	}
}

// Define the custom element
customElements.define('m-gallery-color', MGalleryColor);
