class MSurfaceSwatches extends HTMLElement {
	static get observedAttributes() {
		return ['selected-color'];
	}

	constructor() {
		super();

		const imagesListElement = this.querySelector(':scope > ul');
		const mGalleryColorElements = document.querySelectorAll('m-gallery-color');

		this.template = Object.assign(document.createElement('template'), {
			innerHTML: `
				<nav class="m-surface-swatches__navigation" aria-label="Oberflächen">
					<span class="m-surface-swatches__label"></span>
					${imagesListElement.outerHTML}
				</nav>
			`,
		});
		this.labelElement = this.template.content.querySelector('.m-surface-swatches__label');
		this.buttonElements = this.template.content.querySelectorAll('[data-action="show-surface"]');

		// Event Listener
		this.buttonElements.forEach((buttonElement) => {
			buttonElement.addEventListener('click', () => {
				this.setCurrentSurface(buttonElement.dataset.id);
			});
		});
		mGalleryColorElements.forEach((mGalleryColorElement) => {
			mGalleryColorElement.addEventListener('surfaceUpdate', (event) => {
				this.currentSurface = event.detail;
			});
		});
	}

	get currentSurface() {
		return this.getAttribute('current-surface');
	}

	set currentSurface(value) {
		if (this.currentSurface !== value) {
			this.setAttribute('current-surface', value);
			this.updateNav();
		}
	}

	setCurrentSurface(value) {
		this.currentSurface = value;
		const event = new CustomEvent('surfaceUpdate', { detail: value });
		this.dispatchEvent(event);
	}

	updateNav() {
		const {
			buttonElements,
			currentSurface,
		} = this;

		buttonElements.forEach((buttonElement) => {
			const isPressed = buttonElement.dataset.id === currentSurface;
			buttonElement.toggleAttribute('aria-pressed', isPressed);
			buttonElement.toggleAttribute('aria-current', isPressed);
		});

		const pressedButtonElement = [...buttonElements].find((_) => _.hasAttribute('aria-pressed'));

		this.labelElement.innerHTML = pressedButtonElement.getAttribute('aria-label');
		this.labelElement.style.setProperty('--color', pressedButtonElement.dataset.colorText);
	}

	render() {
		this.innerHTML = '';
		this.appendChild(this.template.content);
	}

	attributeChangedCallback(name, oldValue, newValue) {
		if (name === 'selected-color') {
			this.currentSurface = newValue;
		}
	}

	connectedCallback() {
		// Initial rendering
		this.render();

		// set default attributes
		if (this.getAttribute('selected-color') === null) {
			this.currentSurface = this.buttonElements[0].dataset.id;
		}
	}
}

// Define the custom element
customElements.define('m-surface-swatches', MSurfaceSwatches);
