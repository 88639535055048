if (window.ViewTimeline) {
	if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
		const elements = document.querySelectorAll(':where(.o-content, .o-product-content) > *:not(.m-text, .o-product-teaser), :where(.o-content, .o-product-content) > .m-text > *');
		const vh = window.innerHeight;
		const offsetEnd = vh * 0.3;
		elements.forEach((element) => {
			element.animate(
				{
					translate: ['0 20px', '0 0'],
					opacity: ['0', '0.7', '1'],
				},
				{
					fill: 'both',
					timeline: new ViewTimeline({
						subject: element,
						axis: 'block',
					}),
					easing: 'ease-out',
					rangeStart: { rangeName: 'entry', offset: CSS.px(0) },
					rangeEnd: { rangeName: 'entry', offset: CSS.px(offsetEnd) },
				},
			);
		});
	}
}
