const element = document.querySelector('.o-product-info');
const oProductContentElement = document.querySelector('.o-product-content');
if (element && oProductContentElement) {
	const onResize = () => {
		const isSticky = element.offsetHeight < window.innerHeight;
		element.dataset.variant = isSticky ? null : 'no-sticky';
		if (window.matchMedia('(min-width:calc(1080 / 16 * 1em))').matches && isSticky) {
			let marginBlockEnd = 0;

			const lastFullWidthElements = [...oProductContentElement.querySelectorAll(':scope > .m-teaser')]
				.filter((_) => _.nextElementSibling === null || _.nextElementSibling.classList.contains('m-teaser'));
			lastFullWidthElements.forEach((lastFullWidthElement) => {
				marginBlockEnd += lastFullWidthElement.offsetHeight;
			});
			const { rowGap } = window.getComputedStyle(oProductContentElement);
			element.style.marginBlockEnd = `calc(${marginBlockEnd}px + ${rowGap} * ${lastFullWidthElements.length})`;
		} else {
			element.style.marginBlockEnd = null;
		}
	};

	onResize();
	window.addEventListener('resize', onResize, {
		passive: true,
	});
}
