// const { default: icons } = await import('../foundation/icons.js');
import icons from '../foundation/icons.js';

class MModal extends HTMLElement {
	translations = {
		en: {
			close: 'Close modal',
		},
		de: {
			close: 'Dialog schließen',
		},
	};

	/** Button element which opened the modal */
	targetButton = null;

	constructor() {
		super();

		const language = this.closest('[lang]')?.lang;
		const translations = this.translations[language] ?? this.translations.en;

		this.htmlElement = document.querySelector('html');
		this.elementsToInert = [...document.querySelectorAll('body > *')].filter((_) => _ !== this);

		const elements = this.querySelectorAll(':scope > *');
		this.template = Object.assign(document.createElement('template'), {
			innerHTML: `
				<div class="m-modal__backdrop">
					<div class="m-modal__content">
						<button
							class="m-modal__button-close a-button"
							data-kind="circle"
							data-variant="tertiary"
							data-action="close"
							aria-label="${translations.close}"
						>${icons.close}</button>
					</div>
				</div>
			`,
		});

		this.backdropElement = this.template.content.querySelector('.m-modal__backdrop');
		this.contentElement = this.template.content.querySelector('.m-modal__content');
		const buttonCloseElement = this.template.content.querySelector('.a-button[data-action="close"]');

		elements.forEach((_) => this.contentElement.appendChild(_));

		this.backdropElement.addEventListener('click', (event) => {
			if (event.target === this.backdropElement) {
				this.close();
			}
		});
		buttonCloseElement.addEventListener('click', this.close.bind(this));
		document.addEventListener('keydown', (event) => {
			if (event.key === 'Escape') {
				this.close();
			}
		});

		this.setAttribute('role', 'dialog');
		this.setAttribute('aria-modal', 'true');
	}

	render() {
		this.innerHTML = '';
		this.appendChild(this.template.content);
	}

	showModal() {
		if (!this.hasAttribute('open')) {
			this.targetButton = document.activeElement.tagName.toLowerCase() === 'button' ? document.activeElement : null;
			this.toggleAttribute('open', true);
			this.htmlElement.toggleAttribute('data-no-scroll', true);
			this.elementsToInert.forEach((elementToInert) => {
				elementToInert.toggleAttribute('inert', true);
			});
			this.backdropElement.animate({
				opacity: [0, 1],
			}, {
				duration: 100,
				easing: 'ease-in',
			});
			this.contentElement.animate({
				translate: ['0 1rem', '0 0'],
				opacity: [0, 1],
			}, {
				duration: 250,
				delay: 50,
				fill: 'backwards',
				easing: 'ease-out',
			});
		}
	}

	close() {
		if (this.hasAttribute('open')) {
			this.contentElement.animate({
				translate: ['0 0', '0 1rem'],
				opacity: ['1', '0'],
			}, {
				duration: 200,
				delay: 20,
				easing: 'linear',
			});
			const animation = this.backdropElement.animate({
				opacity: 0,
			}, {
				duration: 200,
				easing: 'linear',
			});

			animation.addEventListener('finish', () => {
				this.toggleAttribute('open', false);
				this.htmlElement.toggleAttribute('data-no-scroll', false);
				this.elementsToInert.forEach((elementToInert) => {
					elementToInert.toggleAttribute('inert', false);
				});
				this.targetButton?.focus();
			});
		}
	}

	connectedCallback() {
		// Initial rendering
		this.render();
	}
}

// Define the custom element
customElements.define('m-modal', MModal);
